<template>
  <div>
    <b-modal
      :id="'EditModalCarModel'"
      title="Изменить модель автомобиля"
      size="s"
      hide-footer
      no-enforce-focus
      :no-close-on-backdrop="true"
      @show="editModalCarModel"
    >
      <b-row class="mt-1">
        <b-col cols="12">
          <label for="">Наименование</label>
          <b-input v-model="bodyType.name" placeholder="Наименование" />
        </b-col>
        <b-col cols="12">
          <label for="">Модель</label>
          <b-input v-model="bodyType.car_model" placeholder="Модель" />
        </b-col>
        <b-col cols="12">
          <label for="">Место от</label>
          <b-input
            type="number"
            v-model.number="bodyType.car_seat_from"
            placeholder="Место от"
          />
          <label for="">Место до</label>
          <b-input
            type="number"
            v-model.number="bodyType.car_seat_before"
            placeholder="Место до"
          />
        </b-col>
      </b-row>
      <b-row class="mb-1">
        <b-col cols="12">
          <label for="">Категорию</label>
          <v-select
            v-model="bodyType.category_car_id"
            :reduce="(options) => options.id"
            placeholder="Категорию"
            label="name"
            :options="category"
            @input="getCarData()"
          >
            <template #no-options>
              <span> Данные отсутствуют! </span>
            </template>
          </v-select>
          <label for="">Бренд</label>
          <v-select
            v-model="bodyType.car_brand_id"
            :disabled="condition"
            :reduce="(options) => options.id"
            placeholder="Бренд"
            label="name"
            :options="car_brands"
          >
            <template #no-options>
              <span> Данные отсутствуют! </span>
            </template>
          </v-select>
          <label for="">Классы</label>
          <v-select
            v-model="bodyType.class_car_id"
            :disabled="condition2"
            :reduce="(options) => options.id"
            placeholder="Классы"
            label="name"
            :options="car_classes"
          >
            <template #no-options>
              <span> Данные отсутствуют! </span>
            </template>
          </v-select>
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col cols="12">
          <b-form-checkbox
            v-model="bodyType.is_active"
            class="custom-control-primary"
          >
            {{ bodyType.is_active ? "Активный" : "Неактивный" }}
          </b-form-checkbox>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" class="d-flex justify-content-between">
          <b-button
            :disabled="submitButtonDisabled"
            class="mt-2 col-md-5"
            variant="primary"
            @click="editBodyType"
          >
            Изменить
          </b-button>
          <b-button
            class="mt-2 col-md-5"
            variant="secondary"
            @click="closeModal"
          >
            Отмена
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>
<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import vSelect from "vue-select";
export default {
  components: {
    vSelect,
    ToastificationContent,
  },
  props: ["categories", "id"],
  data() {
    return {
      bodyType: {
        name: null,
        car_model: null,
        car_brand_id: null,
        class_car_id: null,
        category_car_id: null,
        is_active: null,
        car_seat_from: null,
        car_seat_before: null,
      },
      categoriesSelectDisabled: false,
      submitButtonDisabled: false,
      freight_car: true,
      category: [],
      car_classes: [],
      car_brands: [],
      condition: true,
      condition2: true,
    };
  },
  mounted() {
    this.$http.get("directory/car-categories").then((res) => {
      this.category = res.data;
    });
  },
  methods: {
    clearData() {
      this.bodyType = {
        name: null,
        car_model: null,
        car_brand_id: null,
        class_car_id: null,
        category_car_id: null,
        is_active: null,
        car_seat_from: null,
        car_seat_before: null,
      };
    },
    getCarData() {
      if (this.bodyType.category_car_id != null) {
        this.dataBrandClass();
        this.condition = false;
        this.condition2 = false;
      } else {
        this.condition = true;
        this.condition2 = true;
        this.bodyType.car_brand_id = "";
        this.bodyType.class_car_id = "";
      }
      if (this.bodyType.category_car_id == 3) {
        this.freight_car = false;
      } else {
        this.freight_car = true;
      }
    },
    closeModal() {
      this.$bvModal.hide("EditModalCarModel");
    },
    editBodyType() {
      this.submitButtonDisabled = true;
      this.$http
        .patch(`/car-settings/model-cars/${this.bodyType.id}`, {
          car_model_id: this.bodyType.car_model_id,
          name: this.bodyType.name,
          car_model: this.bodyType.car_model,
          car_brand_id: this.bodyType.car_brand_id,
          class_car_id: this.bodyType.class_car_id,
          category_car_id: this.bodyType.category_car_id,
          is_active: this.bodyType.is_active,
          car_seat_from: this.bodyType.car_seat_from,
          car_seat_before: this.bodyType.car_seat_before,
        })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Модель машины изменена!",
              icon: "CheckIcon",
              variant: "success",
            },
          });
          this.$emit("refresh");
          this.clearData();
          this.closeModal();
        })
        .catch((err) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Некорректные данные!",
              icon: "XIcon",
              variant: "danger",
              text: err.response.data.errors,
            },
          });
        })
        .finally(() => {
          this.submitButtonDisabled = false;
        });
    },
    dataBrandClass() {
      let car_categorie = {
        category_car_id: this.bodyType.category_car_id,
      };
      this.$http
        .post("car-settings/model-cars/data", car_categorie)
        .then((res) => {
          this.car_brands = res.data.car_brands;
          this.car_classes = res.data.car_classes;
        });
    },
    editModalCarModel() {
      this.$http.get(`/car-settings/model-cars/${this.id}/edit`).then((res) => {
        this.bodyType = res.data;
        this.bodyType.is_active = this.bodyType.is_active ? true : false;
        this.dataBrandClass();
      });
    },
  },
};
</script>
